import { registerApplication } from 'single-spa'
import { routeMatcher } from '@toasttab/banquet-match-utils'
import { importSpa } from '../utils'
import { RegistrationFunc } from './registration'

export const adminPortal: RegistrationFunc = (globalCustomProps) => {
  // define feature flags - This should be handled globally here.
  // const flagMatch = featureFlagsMatch(globalCustomProps?.featureFlags)

  // --------------------------------------
  // Define layout spa
  // --------------------------------------

  // TODO: When auto v1 removed from envoy - put portal on /toast/admin
  const adminPortalMatch = routeMatcher(['/*', '*', ''])
  const merchantTemplateViewerMatch = routeMatcher(['/payments/pricing/*'])

  registerApplication({
    name: 'spa-admin-portal',
    activeWhen: ({ pathname }) =>
      Boolean(
        adminPortalMatch(pathname) && !merchantTemplateViewerMatch(pathname)
      ),
    customProps: globalCustomProps,
    app: importSpa('spa-admin-portal')
  })
  registerApplication({
    name: 'merchant-template-viewer-spa',
    activeWhen: ({ pathname }) =>
      Boolean(merchantTemplateViewerMatch(pathname)),
    customProps: globalCustomProps,
    app: importSpa('merchant-template-viewer-spa')
  })
}
