/* istanbul ignore file */
import { addErrorHandler, getAppStatus, LOAD_ERROR } from 'single-spa'
import envFromHostname from '@toasttab/env-from-hostname'
import { handleError } from '../errorHandlers'

export let captureException: (() => void) | ((e: any) => void) = () => {}
export async function setupSentry({ publicKey, projectId }) {
  try {
    const environment = envFromHostname(window.location.hostname)
    const release = process.env.PKG_VERSION
    const Sentry = await System.import('@sentry/browser')
    if (environment !== 'dev' && Sentry) {
      // initialize the sentry project
      const dsn = `https://${publicKey}.ingest.sentry.io/${projectId}`
      const client = new Sentry.BrowserClient({
        dsn,
        environment,
        release,
        transport: Sentry.makeFetchTransport,
        stackParser: Sentry.defaultStackParser,
        integrations: Sentry.defaultIntegrations
      })
      const hub = new Sentry.Hub(client)
      hub.run((currentHub) => {
        currentHub.addBreadcrumb({
          message: 'WBR initiated'
        })
      })
      captureException = (...all) => {
        let eventId = ''
        hub.run((currentHub) => {
          eventId = currentHub.captureException(...all)
        })
        return eventId
      }
    }
    // Add add single-spa error integration
    addErrorHandler((err) => {
      if (getAppStatus(err.appOrParcelName) === LOAD_ERROR) {
        handleError()
        if (
          !err.message.includes('dev.eng.toastteam.com') &&
          captureException
        ) {
          captureException(err)
        } else {
          console.error(
            `
            ----------------------------------------------------------------------------------------------------------
            BANQUET WARNING: importMapOverrides for "${err.appOrParcelName}" is not configured correctly
            
            Helpful hints!
            
            - You may have a override in importMapOverrides development tools.
            - If you are running in local dev - try clearing the "toastweb/public/temp-import-map-overrides" folder.
            ----------------------------------------------------------------------------------------------------------
            
            `
          )
        }
        System.delete(System.resolve(err.appOrParcelName))
      }
    })
  } catch (error) {
    console.error('Error loading Sentry in WBR', error)
  }
}
