import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'focus-visible'

import {
  getCurrentEnvironment,
  ToastEnvironment
} from '@toasttab/current-environment'
import { start } from 'single-spa'
// import { getCapmanOrigin } from '../utils/getCapmanOrigin'
import { devTools, adminPortal } from '../registration'
import { setupSentry } from '../utils/setupSentry'

import { captureException } from '../utils'
import { initAuthClient } from '@toasttab/frontend-authentication'

const env = getCurrentEnvironment()

setupSentry({
  publicKey: 'e8e1f89fd7c6455aa4245b1684f78d72@o37442',
  projectId: '4504776338046976'
})

// REMOVE ONCE WE UPDATE TO REACT 17.0.2
// Reference: https://github.com/facebook/create-react-app/issues/10474
//@ts-ignore
if (!window.crossOriginIsolated) window.SharedArrayBuffer = ArrayBuffer
try {
  // TODO: Modify with package to use Auth0 redirect for universal auth
  // ToastWeb auth client setup
  const run = async (props) => {
    // const globalCustomProps = getGlobalCustomProps(props, {
    //   i18n: await getI18nProps(props),
    //   banquetStartTime: new Date().getTime().toString()
    // })

    // Register dev tools widget(s) here.
    devTools(props)

    // Register admin portal layout spa here.
    adminPortal(props)
    start({ urlRerouteOnly: true })
  }

  initAuthClient({
    originsWithAuth: [window.location.origin],
    environment: env,
    application: 'ADMIN'
  }).then((result) => {
    run({ auth: result.auth, restaurantInfo: {} })
  })
} catch (e) {
  captureException(e)
  if (env === ToastEnvironment.DEV || env === ToastEnvironment.PREPROD) {
    console.error(e)
  }
}
